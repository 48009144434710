module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"강남 유앤미","short_name":"강남 유앤미","description":"강남 유앤미 셔츠룸의 공식 사이트입니다. 특급케어 해드리겠습니다. 방문 전 꼭 전화 부탁드립니다. 주대 15만원 티씨 13만원 룸티5만원 입니다.","start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#000000","lang":"ko","orientation":"portrait-primary","icon":"./src/static/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"69c89343209a38d7763dc2430bac9eb0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
